import React, { useState } from 'react'
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  Alert,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native'
import Header from './components/header'
import TodoItem from './components/todoItem'
import Addtodo from './components/addTodo'

export default function App() {
  function formatDate(date) {
    var monthNames = [
      'januari',
      'februari',
      'maart',
      'april',
      'mei',
      'juni',
      'juli',
      'augustus',
      'september',
      'oktober',
      'november',
      'december',
    ]

    var day = date.getDate()
    var monthIndex = date.getMonth()
    var year = date.getFullYear()

    return day + ' ' + monthNames[monthIndex] + ' ' + year
  }

  function add_months_todate(dt, no_of_month) {
    return new Date(dt.setDate(dt.getDate() + no_of_month))
  }

  let start_date = new Date()

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        Keyboard.dismiss()
      }}
    >
      <View style={styles.container}>
        <Header />
        <View style={{ flex: 15, justifyContent: 'center' }}>
          <Text style={styles.FirstItem}>{'Alle diensten tot en met'}</Text>
          <Text style={styles.list}>
            {formatDate(add_months_todate(new Date(), 14))}
          </Text>
          <Text style={styles.item}>
            {'Vaste diensten en 1 vrije dienst tot'}
          </Text>
          <Text style={styles.list}>
            {formatDate(add_months_todate(new Date(), 30))}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },

  content: {
    padding: 40,
  },

  FirstItem: {
    fontSize: 15,
    textAlign: 'center',
    alignSelf: 'center',
  },

  item: {
    marginTop: 50,
    fontSize: 15,
    textAlign: 'center',
    alignSelf: 'center',
  },
  list: {
    marginTop: 10,
    fontSize: 25,
    textAlign: 'center',
  },
})
