import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

export default function Header() {
  return (
    <View style={styles.header}>
      <Text style={styles.title}>Dag App</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    height: 75,
    backgroundColor: 'coral',
    justifyContent: 'center',
  },

  title: {
    textAlign: 'left',
    color: '#fff',
    fontSize: 20,
    fontWeight: 'bold',
    paddingLeft: 30,
  },
})
